import { Capacitor } from '@capacitor/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater';

export const useBundleStore = defineStore('bundle', () => {

    const { apiBundle, appBundleVersion, appVersion } = useRuntimeConfig().public

    const updateBundle = async () => {

        if (Capacitor.isNativePlatform()) {

            const data = await $fetch(apiBundle, {
                params: {
                    t: Date.now(),
                }
            })

            if (data) {

                const version = await CapacitorUpdater.download(data)

                try {
                    await CapacitorUpdater.set(version)
                    await CapacitorUpdater.notifyAppReady()
                } catch (err) {
                    console.log(err)
                }
            }

        }
    }

    const getVersion = () => {

        const fetchResult = createEventHook<any>()
        const fetchError = createEventHook<any>()

        if (Capacitor.isNativePlatform()) {

            CapacitorUpdater.current().then((data) => {

                fetchResult.trigger({
                    platform: Capacitor.getPlatform(),
                    appVersion: data.native,
                    bundleVersion: data.bundle.version != 'builtin' ? data.bundle.version : appBundleVersion
                })

            }).catch((error) => {
                fetchError.trigger(error.data)
            })

        } else {

            const version = {
                platform: Capacitor.getPlatform(),
                appVersion: appVersion,
                bundleVersion: appBundleVersion
            }
            Promise.resolve(version).then((data) => {
                fetchResult.trigger(data)
            })
        }

        return { onResult: fetchResult.on, onError: fetchError.on }
    }

    return {
        updateBundle,
        getVersion
    }

})